import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { productService } from "./productsService";
import { toast } from "react-toastify";

export const getAllProduct = createAsyncThunk(
  "product/get",
  async (thunkAPI) => {
    try {
      const response = await productService.getProducts();
      // console.log(response);
      if (response.data.result) {
        const data = response.data.product;
        const results = {
          data: data,
          msg: response.data.msg,
        };

        return results;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAll = createAsyncThunk(
  "product/getall",
  async (params, { rejectWithValue }) => {
    try {
      // call Api
      const response = await productService.getAll(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.errors[0].msg);
      }
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getAProduct = createAsyncThunk(
  "product/getaproduct",
  async (slug, thunkAPI) => {
    try {
      return await productService.getSingleProduct(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const addToWishList = createAsyncThunk(
  "product/wishlist",
  async (prodSlug, thunkAPI) => {
    try {
      return await productService.addToWishList(prodSlug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const addRating = createAsyncThunk(
  "product/rating",
  async (data, thunkAPI) => {
    try {
      return await productService.rateProduct(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const productState = {
  product: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const productSlice = createSlice({
  name: "product",
  initialState: productState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(getAllProduct.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(getAllProduct.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = false;
      //   state.isSuccess = true;
      //   state.data = action?.payload?.data;
      // })
      // .addCase(getAllProduct.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.isSuccess = false;
      //   state.message = action.error;
      // })
      .addCase(getAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action?.payload?.data;
        state.totalPage = action?.payload?.totalPage;
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(addToWishList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToWishList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.addToWishlist = action.payload;
        state.message = "Product Add To WishList !";
      })
      .addCase(addToWishList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singleProduct = action.payload;
        state.message = "Product Fetched SuccessFully !";
      })
      .addCase(getAProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(addRating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.rating = action.payload;
        state.message = "Rating added SuccessFully !";
        if (state.isSuccess) {
          toast.success("Đánh giá thành công !");
        }
      })
      .addCase(addRating.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });
  },
});

export default productSlice.reducer;
