import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { emailService } from "./emailService";

export const createEmailMarketing = createAsyncThunk(
  "email/post",
  async (emailData, { rejectWithValue }) => {
    try {
      return await emailService.createEmail(emailData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const emailState = {
  email: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const emailSlice = createSlice({
  name: "email",
  initialState: emailState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEmailMarketing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEmailMarketing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdEmail = action.payload.newEmail;
        if (state.isSuccess === true) {
          toast.success(action.payload.message);
        }
      })
      .addCase(createEmailMarketing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });
  },
});

export default emailSlice.reducer;
