import { axiosClient, base_url } from "../../utils/axiosConfig";

const createEmail = async (emailData) => {
  const response = await axiosClient.post(`${base_url}email/`, emailData);

  if (response.data) {
    return response.data;
  }
};

export const emailService = {
  createEmail,
};
