import "./style.css";
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { FaShippingFast } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { Modal } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";

import Meta from "../../components/Meta";
import Container from "../../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { createAnOrder, getUserCart } from "../../features/user/userSlice";
import logo from "../../images/logo-header.png";

import { getAllProduct } from "../../features/products/productSlice";
import { configToken } from "../../utils/verifyToken";
import CountdownTimer from "../../utils/CountdownTimer";

const shippingSchema = object({
  firstName: string().required("Vui lòng điền họ và tên đệm!"),
  lastName: string().required("Vui lòng điền tên!"),
  address: string().required("Vui lòng nhập địa chỉ nhận hàng"),
  city: string().required("Vui lòng chọn tỉnh/thành phố"),
  district: string().required("Vui lòng chọn quận/huyện"),
  ward: string().required("Vui lòng chọn phường/xã"),
  mobile: string()
    .default("")
    .nullable()
    .required("Nhập số điện thoại liên hệ"),
  pincode: string().required("Vui lòng nhập mã bưu điện"),
  other: string(),
});

const HOST_APP_API_PROVINCE = `https://vapi.vnappmob.com`;

const Checkout = () => {
  const dispatch = useDispatch();

  const [totalAmount, setTotalAmount] = useState(null);
  const [shippingInfo, setShippingInfo] = useState(null);
  const [cartProductState, setCartProductState] = useState([]);
  const authState = useSelector((state) => state?.auth?.user);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [open, setOpen] = useState(false);

  const cartState = useSelector((state) => state?.auth?.cartProduct?.cart);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      district: "",
      ward: "",
      mobile: "",
      pincode: "",
      other: "",
    },
    validationSchema: shippingSchema,
    onSubmit: (values) => {
      setShippingInfo(values);
      setTimeout(() => {
        checkOutHandler();
      }, 200);
    },
  });

  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < cartState?.length; index++) {
      sum =
        sum +
        Number(cartState[index]?.quantity) * Number(cartState[index]?.price);
      setTotalAmount(sum);
    }
  }, [cartState]);

  const getDataUserCart = useCallback(async () => {
    await dispatch(getUserCart(configToken));
  }, [dispatch]);

  useEffect(() => {
    getDataUserCart();
  }, [getDataUserCart]);

  useEffect(() => {
    let items = [];
    for (let index = 0; index < cartState?.length; index++) {
      items.push({
        product: cartState[index]?.productId?._id,
        quantity: cartState[index]?.quantity,
        color: cartState[index]?.productId?.color,
        price: cartState[index]?.price,
      });
    }
    setCartProductState(items);
  }, [cartState]);

  const getData = useCallback(async () => {
    await dispatch(getAllProduct());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const checkOutHandler = async () => {
    await dispatch(
      createAnOrder({
        shippingInfo,
        orderItems: cartProductState,
        totalPrice: totalAmount > 500000 ? totalAmount : totalAmount + 30000,
        totalPriceAfterDiscount: totalAmount,
      }),
    );
  };

  const fecthDataProvince = async () => {
    await axios
      .get(`${HOST_APP_API_PROVINCE}/api/province/`)
      .then((res) => setProvinces(res?.data?.results))
      .catch((err) => console.log(err));
  };

  const fecthDataDistrict = useCallback(async () => {
    await axios
      .get(
        `${HOST_APP_API_PROVINCE}/api/province/district/${formik.values.city}`,
      )
      .then((res) => setDistricts(res?.data?.results))
      .catch((err) => console.log(err));
  }, [formik.values.city]);

  const fecthDataWard = useCallback(async () => {
    await axios
      .get(
        `${HOST_APP_API_PROVINCE}/api/province/ward/${formik.values.district}`,
      )
      .then((res) => setWards(res?.data?.results))
      .catch((err) => console.log(err));
  }, [formik.values.district]);

  useEffect(() => {
    fecthDataProvince();
    if (formik.values.city) {
      fecthDataDistrict();
    }

    if (formik.values.district) {
      fecthDataWard();
    }
  }, [
    fecthDataDistrict,
    fecthDataWard,
    formik.values.city,
    formik.values.district,
  ]);

  const handleSelectCity = (e) => {
    formik.setFieldValue("city", e.target.value);
  };

  const handleSelectDistrict = (e) => {
    formik.setFieldValue("district", e.target.value);
  };

  const handleSelectWard = (e) => {
    formik.setFieldValue("ward", e.target.value);
  };
  const [isCountdownPaused, setIsCountdownPaused] = useState(false);

  const handleModalClose = () => {
    setIsCountdownPaused(true);
    setOpen(false);
  };

  const handleModalOpen = () => {
    setIsCountdownPaused(false);
    setOpen(true);
  };

  return (
    <>
      <Meta title="Thanh toán" />
      <Container class1="checkout-wrapper py-2 home-wrapper-2">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-7 mb-3">
            <div className="checkout-left-data">
              <h3 className="website-name">Ramat Note Book</h3>
              <nav
                style={{ "--bs-bradcrumb-divider": ">" }}
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link className="text-dark" to="/cart">
                      Giỏ hàng
                    </Link>
                  </li>
                  &nbsp;
                  <li className="breadcrumb-item" aria-current="page">
                    Thông tin đặt hàng
                  </li>
                </ol>
              </nav>
              {/* Thông tin đặt hàng */}
              <h4 className="title">Thông tin đặt hàng</h4>
              <div className="tab-content" id="myTabContent">
                <div id="home" role="tabpanel" aria-labelledby="home-tab">
                  <p className="user-details">
                    {authState?.firstname + authState?.lastname} (
                    {authState?.email})
                  </p>
                  <h4 className="mb-3">Địa chỉ giao hàng</h4>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="d-flex gap-15 flex-wrap justify-content-between"
                  >
                    <div className="d-flex align-items-center w-100 gap-3">
                      <div className="w-50">
                        <select
                          name="city"
                          className="form-control form-select"
                          onChange={(e) => handleSelectCity(e)}
                          onBlur={formik.handleBlur("city")}
                          value={formik.values.city}
                        >
                          <option value="" selected disabled>
                            ---Chọn tỉnh thành---
                          </option>
                          {provinces &&
                            provinces?.map((item) => {
                              return (
                                <option
                                  key={item?.province_id}
                                  value={item?.province_id}
                                >
                                  {item?.province_name}
                                </option>
                              );
                            })}
                        </select>
                        <div className="errors">
                          {formik.touched.city && formik.errors.city}
                        </div>
                      </div>
                      <div className="w-50">
                        <select
                          name="city"
                          className="form-control form-select"
                          onChange={(e) => handleSelectDistrict(e)}
                          onBlur={formik.handleBlur("district")}
                          value={formik.values.district}
                        >
                          <option value="" selected disabled>
                            ---Chọn quận huyện---
                          </option>
                          {districts &&
                            districts?.map((item) => {
                              return (
                                <option
                                  key={item?.district_id}
                                  value={item?.district_id}
                                >
                                  {item?.district_name}
                                </option>
                              );
                            })}
                        </select>
                        <div className="errors">
                          {formik.touched.district && formik.errors.district}
                        </div>
                      </div>
                    </div>
                    <div className="w-100">
                      <select
                        name="city"
                        className="form-control form-select"
                        onChange={(e) => handleSelectWard(e)}
                        onBlur={formik.handleBlur("ward")}
                        value={formik.values.ward}
                      >
                        <option value="" selected disabled>
                          ---Chọn phường xã---
                        </option>
                        {wards &&
                          wards?.map((item) => {
                            return (
                              <option key={item?.ward_id} value={item?.ward_id}>
                                {item?.ward_name}
                              </option>
                            );
                          })}
                      </select>
                      <div className="errors">
                        {formik.touched.ward && formik.errors.ward}
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <input
                        name="firstName"
                        type="text"
                        placeholder="Họ và tên đệm"
                        className="form-control"
                        onChange={formik.handleChange("firstName")}
                        onBlur={formik.handleBlur("firstName")}
                        value={formik.values.firstName}
                      />
                      <div className="errors">
                        {formik.touched.firstName && formik.errors.firstName}
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <input
                        name="lastName"
                        type="text"
                        placeholder="Tên"
                        className="form-control"
                        onChange={formik.handleChange("lastName")}
                        onBlur={formik.handleBlur("lastName")}
                        value={formik.values.lastName}
                      />
                      <div className="errors">
                        {formik.touched.lastName && formik.errors.lastName}
                      </div>
                    </div>
                    <div className="w-100">
                      <input
                        name="address"
                        type="text"
                        placeholder="Địa chỉ nhận hàng"
                        className="form-control"
                        onChange={formik.handleChange("address")}
                        onBlur={formik.handleBlur("address")}
                        value={formik.values.address}
                      />
                      <div className="errors">
                        {formik.touched.address && formik.errors.address}
                      </div>
                    </div>
                    <div className="w-100">
                      <input
                        name="mobile"
                        type="number"
                        placeholder="Số điện thoại người nhận hàng"
                        className="form-control"
                        onChange={formik.handleChange("mobile")}
                        onBlur={formik.handleBlur("mobile")}
                        value={formik.values.mobile}
                      />
                      <div className="errors">
                        {formik.touched.mobile && formik.errors.mobile}
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <input
                        name="pincode"
                        type="number"
                        placeholder="Mã zip"
                        className="form-control"
                        onChange={formik.handleChange("pincode")}
                        onBlur={formik.handleBlur("pincode")}
                        value={formik.values.pincode}
                      />
                      <div className="errors">
                        {formik.touched.pincode && formik.errors.pincode}
                      </div>
                    </div>
                    <div className="w-100">
                      <textarea
                        name="other"
                        type="number"
                        placeholder="Ghi chú đơn hàng"
                        className="form-control"
                        onChange={formik.handleChange("other")}
                        onBlur={formik.handleBlur("other")}
                        value={formik.values.other}
                      />
                      <div className="errors">
                        {formik.touched.other && formik.errors.other}
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <Link
                          to="/cart"
                          className="text-dark d-flex align-items-center gap-10"
                        >
                          <BiArrowBack className="me-2" />
                          Quay lại giỏ hàng
                        </Link>
                        <Link to="/cart" className="button">
                          Tiếp tục mua hàng
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Thông tin đơn hàng */}
          <div
            className="col-12 col-md-5 col-lg-5 bg-white"
            style={{
              borderRadius: "10px",
              boxShadow: "0px 0px 5px",
            }}
          >
            <div className="border-2 py-3">
              <h3 className="text-center fw-bold text-red pb-2">
                Chi tiết đơn hàng
              </h3>
              {cartState &&
                cartState?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="d-flex gap-2 justify-content-between">
                        <div className="w-75 d-flex gap-10">
                          <div className="d-flex gap-10 mt-3">
                            <div className="w-25 position-relative">
                              <span
                                style={{ top: "-10px", right: "2px" }}
                                className="badge bg-secondary text-white rounded-circle p-2 position-absolute"
                              >
                                {item?.quantity}
                              </span>
                              <img
                                src={item?.productId?.images?.[0].url}
                                className="img-fluid rounded-3"
                                alt="Banner Product"
                              />
                            </div>
                            <div>
                              <h5 className="total-price fw-bold">
                                {item?.productId?.title}
                              </h5>
                              <div className="total-price d-flex gap-1">
                                <p>Mã sản phẩm: </p>
                                {item?.productId?.codeProduct}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <h5 className="total">
                            {(item?.productId?.price).toLocaleString("en-US", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </h5>
                        </div>
                      </div>
                      <div className="border-bottom py-4 pb-1">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="total">Tạm tính</p>
                          <p className="total-price">
                            {(
                              item?.productId?.price * item?.quantity
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="mb-0 total flex">
                    <FaShippingFast size={20} className="mr-4" />
                    Phí Ship
                  </p>
                  {totalAmount < 500000 ? (
                    <p className="mb-0 total-price">
                      {(30000).toLocaleString("en-US", {
                        style: "currency",
                        currency: "VND",
                      })}
                    </p>
                  ) : (
                    <p className="fs-5">Free Ship</p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center py-2">
                <h4 className="total fw-bold fs-5">Tổng cộng</h4>
                <h5 className="total-price fs-5 fw-bold">
                  {totalAmount < 500000
                    ? (totalAmount + 30000).toLocaleString("en-US", {
                        style: "currency",
                        currency: "VND",
                      })
                    : totalAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "VND",
                      })}
                </h5>
              </div>
              <div className="d-flex flex-column mx-auto">
                <button
                  className="w-75 mb-2"
                  type="button"
                  style={{
                    outline: "none",
                    backgroundColor: "#ae2070",
                    color: "white",
                    borderRadius: "5px",
                    border: "none",
                    padding: "5px",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  onClick={handleModalOpen}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/vi/f/fe/MoMo_Logo.png"
                    width="40px"
                    height="40px"
                    alt="Logo momo"
                  />
                  Thanh toán nhanh bằng MoMo
                </button>
                <button
                  className="w-75"
                  type="submit"
                  onClick={formik.handleSubmit}
                  style={{
                    outline: "none",
                    backgroundColor: "none",
                    border: "none",
                    margin: "0 auto",
                    borderRadius: "5px",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={logo}
                    width="100px"
                    height="40px"
                    alt="Logo Ramat"
                  />
                  Thanh toán
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Thanh toán MoMo */}
        <Modal
          title="Vui lòng quét mã thanh toán"
          open={open}
          onCancel={() => setOpen(false)}
          cancelText="Hủy"
          okText="Thanh toán"
        >
          <div className="">
            <div className="modal-body">
              <div className="d-flex mx-auto align-items-center justify-content-center">
                <img
                  src={`https://img.vietqr.io/image/970416-243400389-compact2.png?amount=${totalAmount}&addInfo="Chuyển khoản mua sản phẩm Product 1"&accountName="Nguyen Thuy Ngoc Nhi"`}
                  width="60%"
                  height="100%"
                  style={{ borderRadius: "5px" }}
                  alt="QR Payment"
                />
              </div>
              <CountdownTimer
                initialSeconds={60}
                isPaused={isCountdownPaused}
                onClose={handleModalClose}
              />
            </div>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default Checkout;
