import { axiosClient, base_url } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
const getTokenfromLocalStorage = localStorage.getItem("customer")
  ? JSON.parse(localStorage.getItem("customer"))
  : null;

// console.log(getTokenfromLocalStorage);
export const config = {
  headers: {
    Authorization: `Bearer ${
      getTokenfromLocalStorage !== null ? getTokenfromLocalStorage.token : ""
    }`,
    Accept: "application/json",
  },
};

const register = async (userData) => {
  const response = await axiosClient.post(`${base_url}user/register`, userData);
  if (response.data) {
    return response.data;
  } else {
    return;
  }
};

const login = async (userData) => {
  const response = await axiosClient.post(`${base_url}user/login`, userData);
  if (response.data) {
    localStorage.setItem("customer", JSON.stringify(response.data));
    localStorage.setItem("token", JSON.stringify(response.data?.token));
  }
  return response.data;
};

const ChangePassword = async (data) => {
  const response = await axiosClient.put(
    `${base_url}user/password`,
    data.data,
    data.configUpdateUser,
    config,
  );
  return response.data;
};

const getUserWishlist = async () => {
  const response = await axiosClient.get(`${base_url}user/wishlist`, config);
  if (response.data) {
    return response.data;
  }
};

const addToCart = async (cartData) => {
  const response = await axiosClient.post(`${base_url}cart/`, cartData, config);
  if (response.data) {
    return response.data;
  }
};

const updateProductQuantityFromCart = async (cartDetail) => {
  const response = await axiosClient.delete(
    `${base_url}cart/update-product-cart/${cartDetail.cartItemId}/${cartDetail.quantity}`,
    config,
  );
  if (response.data) {
    return response.data;
  }
};

const getCart = async (data) => {
  const response = await axiosClient.get(`${base_url}cart`, data);
  if (response.data) {
    return response.data;
  }
};

const removeProductFromCart = async (data) => {
  const response = await axiosClient.delete(
    `${base_url}cart/delete-product-cart/${data.id}`,
    data.configCart,
  );
  if (response.data) {
    return response.data;
  }
};

const createOrder = async (orderDetail) => {
  const response = await axiosClient.post(
    `${base_url}order/create-order`,
    orderDetail,
    config,
  );
  if (response.data) {
    return response.data;
  }
};

const getUserOrders = async () => {
  const response = await axiosClient.get(
    `${base_url}user/get-my-order`,
    config,
  );
  if (response.data) {
    return response.data;
  }
};

const updateUser = async (data) => {
  const response = await axiosClient.put(
    `${base_url}user/edit-user`,
    data.data,
    data.configUpdateUser,
  );
  if (response.data) {
    return response.data;
  }
};

const forgotPassword = async (data) => {
  const response = await axiosClient.post(`${base_url}user/forgot`, data);
  if (response.data) {
    return response.data;
  }
};

const resetPassword = async (data) => {
  const response = await axiosClient.put(
    `${base_url}user/reset-password/${data.token}`,
    { password: data?.password },
  );
  if (response.data) {
    return response.data;
  }
};

const emptyCart = async (data) => {
  const response = await axiosClient.delete(`${base_url}user/empty-cart`, data);
  if (response.data) {
    return response.data;
  }
};

export const authService = {
  register,
  login,
  getUserWishlist,
  addToCart,
  getCart,
  removeProductFromCart,
  updateProductQuantityFromCart,
  createOrder,
  getUserOrders,
  updateUser,
  forgotPassword,
  resetPassword,
  emptyCart,
  ChangePassword,
};
