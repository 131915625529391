import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact/Contact";
import OurStore from "./pages/Product/OurStore";
import Blog from "./pages/Blog/Blog";
import Wishlist from "./pages/Product/Wishlist";
import Login from "./pages/Auth/Login";
import Forgotpassword from "./pages/Auth/Forgotpassword";
import Signup from "./pages/Auth/Signup";
import SingleBlog from "./pages/Blog/SingleBlog";
import PrivacyPolicy from "./pages/Policy/PrivacyPolicy";
import RefundPolicy from "./pages/Policy/RefundPolicy";
import ShippingPolicy from "./pages/Policy/ShippingPolicy";
import TermAndContions from "./pages/Policy/TermAndContions";
import SingleProduct from "./pages/Product/SingleProduct";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/CheckOut/Checkout";
import HandmadeGift from "./pages/HandMadeGift/HandmadeGift";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { OpenRoutes } from "./routing/OpenRoutes";
import Orders from "./pages/Cart/Orders";
import Profile from "./pages/User/Profile";
import ChangePassword from "./pages/User/changePass";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="product" element={<OurStore />} />
            <Route path="product/:slug" element={<SingleProduct />} />
            <Route path="handmade-gift" element={<HandmadeGift />} />
            <Route path="blog" element={<Blog />} />
            <Route path="blog/:id" element={<SingleBlog />} />
            <Route
              path="cart"
              element={
                <PrivateRoutes>
                  <Cart />
                </PrivateRoutes>
              }
            />
            <Route
              path="my-profile"
              element={
                <PrivateRoutes>
                  <Profile />
                </PrivateRoutes>
              }
            />
            <Route
              path="change-password"
              element={
                <PrivateRoutes>
                  <ChangePassword />
                </PrivateRoutes>
              }
            />
            <Route
              path="my-orders"
              element={
                <PrivateRoutes>
                  <Orders />
                </PrivateRoutes>
              }
            />
            <Route
              path="checkout"
              element={
                <PrivateRoutes>
                  <Checkout />
                </PrivateRoutes>
              }
            />
            <Route
              path="wishlist"
              element={
                <PrivateRoutes>
                  <Wishlist />
                </PrivateRoutes>
              }
            />
            <Route
              path="login"
              element={
                <OpenRoutes>
                  <Login />
                </OpenRoutes>
              }
            />
            <Route path="forgot-password" element={<Forgotpassword />} />
            <Route
              path="signup"
              element={
                <OpenRoutes>
                  <Signup />
                </OpenRoutes>
              }
            />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="huong-dan-dat-hang" element={<RefundPolicy />} />
            <Route path="chinh-sach-van-chuyen" element={<ShippingPolicy />} />
            <Route path="chinh-sach-thanh-toan" element={<TermAndContions />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
