import React, { useEffect } from "react";
import BreakCrumb from "../../components/BreakCrumb";
import Container from "../../components/Container";
import Meta from "../../components/Meta";
import "./style.css";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Meta title="Privacy Policy" />
      <BreakCrumb title="Privacy Policy" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy"></div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
