/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
const SpecialProduct = (props) => {
  const {
    title,
    category,
    price,
    quantity,
    sold,
    slug,
    image,
    discount,
    dateSale,
  } = props;
  const navigate = useNavigate();
  const [timeDays, setTimeDays] = useState("00");
  const [timeHours, setTimeHours] = useState("00");
  const [timeMinutes, setTimeMinutes] = useState("00");
  const [timeSeconds, setTimeSeconds] = useState("00");

  const saleString = dateSale.toString().substring(0, 10);
  const nowDate = new Date().toISOString().substring(0, 10);

  // Timer Countdown
  let interval = useRef();

  const startTimer = () => {
    const countDownDate = new Date(dateSale).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        // stop timer
        clearInterval(interval.current);
      } else {
        // update timer
        setTimeDays(days);
        setTimeHours(hours);
        setTimeMinutes(minutes);
        setTimeSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {saleString !== nowDate ? (
        <div className="special-product-card position-relative">
          <div className="position-absolute tag-percent">
            <div className="position-absolute tag-percent">
              Giảm <br /> {((discount * 100) / price).toFixed(0)}%
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-12">
              <img src={image} className="img-fluid" alt="Image Flash Sale" />
            </div>
            <div className="col-md-8 col-12">
              <h4
                className="title fw-bold"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/product/` + slug)}
              >
                {title}
              </h4>
              <h6 className="brand">{category}</h6>
              <p className="price">
                <span className="text-danger fw-bold fs-3">
                  {discount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "VND",
                  })}
                </span>
                &nbsp;
                <strike>
                  {price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "VND",
                  })}
                </strike>
              </p>
              <div className="discount-bill gap-10 text-center mt-2">
                <div className="d-flex mx-auto">
                  <div className="d-flex justify-content-center mx-2">
                    <div>
                      <p className="circle_countdown bg-danger text-white rounded mx-1 my-1 px-3 py-2">
                        {timeDays}
                      </p>
                      <p>
                        <small className="fw-bold">Days</small>
                      </p>
                    </div>
                    <span
                      style={{ marginLeft: "1rem" }}
                      className="my-2 fs-4 fw-bold"
                    >
                      :
                    </span>
                  </div>
                  <div className="d-flex justify-content-center mx-2">
                    <div>
                      <p className="circle_countdown bg-danger text-white rounded mx-1 my-1 px-3 py-2">
                        {timeHours}
                      </p>
                      <p>
                        <small className="fw-bold">Hours</small>
                      </p>
                    </div>
                    <span
                      style={{ marginLeft: "1rem" }}
                      className="my-2 fs-4 fw-bold"
                    >
                      :
                    </span>
                  </div>
                  <div className="d-flex justify-content-center mx-2">
                    <div>
                      <p className="circle_countdown bg-danger text-white rounded mx-1 my-1 px-3 py-2">
                        {timeMinutes}
                      </p>
                      <p>
                        <small className="fw-bold">Minutes</small>
                      </p>
                    </div>
                    <span
                      style={{ marginLeft: "1rem" }}
                      className="my-2 fs-4 fw-bold"
                    >
                      :
                    </span>
                  </div>
                  <div className="d-flex justify-content-center mx-2">
                    <div>
                      <p className="circle_countdown bg-danger text-white rounded mx-1 my-1 px-3 py-2">
                        {timeSeconds}
                      </p>
                      <p>
                        <small className="fw-bold">Seconds</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prod-count my-3">
                <p>Số lượng: {quantity - sold}</p>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: 100 - (sold * 100) / quantity + "%" }}
                    aria-valuenow={100 - (sold * 100) / quantity}
                    aria-valuemin={quantity}
                    aria-valuemax={sold + quantity}
                  />
                </div>
              </div>
              <Link to={`/product/` + slug} className="button">
                Thêm giỏ hàng
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SpecialProduct;
