import { axiosClient, base_url, config } from "../../utils/axiosConfig";

const getProducts = async () => {
  return await axiosClient.get(`${base_url}product/`);
};

const getAll = async (params) => {
  const url = `${base_url}product/getall`;
  return await axiosClient({ method: "GET", url, params });
};

const getSingleProduct = async (slug) => {
  const response = await axiosClient.get(`${base_url}product/${slug}`);

  if (response.data) {
    return response.data;
  }
};

const addToWishList = async (prodSlug) => {
  const response = await axiosClient.put(
    `${base_url}product/wishlist`,
    { prodSlug },
    config,
  );
  if (response.data) {
    return response.data;
  }
};

const rateProduct = async (data) => {
  const response = await axiosClient.put(
    `${base_url}product/rating`,
    data,
    config,
  );
  if (response.data) {
    return response.data;
  }
};

export const productService = {
  getProducts,
  addToWishList,
  getSingleProduct,
  rateProduct,
  getAll,
};
