/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import wish from "../images/wish.svg";
import book02 from "../images/book-02.png";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
import { useDispatch } from "react-redux";
import { addToWishList } from "../features/products/productSlice";

const ProductCard = (props) => {
  const { grid, data } = props;

  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();

  const addToWish = (slug) => {
    dispatch(addToWishList(slug));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {data &&
        data
          .filter((data) => data?.status === "true")
          .map((item, index) => (
            <div
              key={index}
              className={`${
                location.pathname === "/product" ? `gr-${grid}` : "col-6"
              }`}
            >
              <div className="product-card position-relative my-2 w-100 col-12">
                <div className="position-absolute tag-percent">
                  Giảm <br />
                  {((item?.discount * 100) / item?.price).toFixed(0)}%
                </div>
                <div className="wishlist-icon position-absolute">
                  <button className="border-0 bg-transparent">
                    <img
                      onClick={() => addToWish(item?.slug)}
                      src={wish}
                      alt="wish"
                    />
                  </button>
                </div>
                <div className="product-image">
                  <img
                    src={item?.images?.[0]?.url}
                    className="img-fluid"
                    alt="Product Image"
                  />
                  <img
                    src={item?.images?.[1]?.url || book02}
                    className="img-fluid"
                    alt="Product Image"
                  />
                </div>
                <div className="product-details">
                  <h6 className="type">{item?.brand}</h6>
                  <h5
                    className="product-title"
                    onClick={() => navigate(`/product/` + item?.slug)}
                  >
                    {item?.title}
                  </h5>
                  <p
                    className={`desciption ${
                      grid === 12 ? "d-block" : "d-none"
                    }`}
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                  <div className="price d-flex justify-content-between">
                    <div>
                      <span className="text-danger fw-bold fs-5">
                        {item?.discount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "VND",
                        })}
                      </span>
                      &nbsp;{" "}
                      <strike>
                        {item?.price?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "VND",
                        })}
                      </strike>
                    </div>
                    <span className="d-flex align-items-center gap-1 sold">
                      <span>Đã bán</span>
                      {item?.sold}
                    </span>
                  </div>
                </div>
                <div className="action-bar position-absolute">
                  <div className="d-flex flex-column gap-15">
                    <button className="border-0 bg-transparent">
                      <img
                        onClick={() => navigate(`/product/` + item?.slug)}
                        src={view}
                        alt="view"
                      />
                    </button>
                    <button className="border-0 bg-transparent">
                      <img src={addcart} alt="addcart" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
    </>
  );
};

export default ProductCard;
