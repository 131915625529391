import React, { useEffect } from "react";
import BreakCrumb from "../../components/BreakCrumb";
import Container from "../../components/Container";
import Meta from "../../components/Meta";
import "./style.css";
const TermAndContions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Meta title="Chính sách thanh toán" />
      <BreakCrumb title="Chính sách thanh toán" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
              <h1 className="text-center fw-bold text-uppercase">
                chính sách thanh toán
              </h1>
              <p className="fs-5">
                Các bạn có thể lựa chọn 1 trong 2 hình thức thanh toán khi mua
                hàng online như sau:
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <p className="fs-5">
                    <strong>1. THANH TOÁN KHI NHẬN HÀNG (COD)</strong> <br />
                    COD là hình thức khách hàng thanh toán tiền mặt trực tiếp
                    cho nhân viên vận chuyển khi nhận hàng. Các đơn hàng có giá
                    trị tiền hàng trên 1.000.000 VNĐ cần thanh toán bằng hình
                    thức chuyển khoản trước khi giao hàng.
                  </p>
                </li>
                <li>
                  <p className="fs-5">
                    <strong>2. THANH TOÁN TRẢ TRƯỚC</strong> <br /> Các bạn có
                    thể thanh toán trước đơn hàng bằng hình thức chuyển khoản.
                    hoặc chuyển qua Momo
                    <br /> Thông tin tài khoản ngân hàng: Ngân hàng ACB
                    <br /> Chi nhánh TP. Hồ Chí Minh
                    <br /> Số tài khoản: 243400389
                    <br /> Chủ tài khoản: Nguyễn Thuỵ Ngọc Nhi <br />
                    <hr width="30%" />
                    Momo: 077 777 293 (Nguyễn Thuỵ Ngọc Nhi) <br />
                    <strong>Nội dung chuyển khoản</strong> (Bắt buộc): SĐT đặt
                    hàng + tổng số tiền
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default TermAndContions;
