import React, { useEffect } from "react";
import BreakCrumb from "../../components/BreakCrumb";
import Container from "../../components/Container";
import Meta from "../../components/Meta";
import "./style.css";
const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Meta title="Hướng dẫn đặt hàng" />
      <BreakCrumb title="Hướng dẫn đặt hàng" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
              <h1 className="text-center text-uppercase fw-bold">
                HƯỚNG DẪN ĐẶT HÀNG
              </h1>
              <p className="fs-5">
                <strong>
                  1. Đặt sổ có sẵn: Bạn có thể đặt sổ qua 4 kênh online sau
                </strong>
                <ul>
                  <li>
                    Website:
                    <a
                      href="https://ramatnotebook.com"
                      style={{ marginLeft: "4px" }}
                    >
                      https://ramatnotebook.com
                    </a>
                  </li>
                  <li>
                    Facebook:
                    <a
                      href="https://www.facebook.com/ramat.notebook"
                      style={{ marginLeft: "4px" }}
                    >
                      RamatNoteBook
                    </a>
                  </li>
                  <li>
                    Shopee:
                    <a
                      href="https://shopee.vn/ramat.craft"
                      style={{ marginLeft: "4px" }}
                    >
                      https://shopee.vn/ramat.craft
                    </a>
                  </li>
                  <li>
                    Lazada:
                    <a
                      href="https://www.lazada.vn/shop/ramat-craft"
                      style={{ marginLeft: "4px" }}
                    >
                      https://www.lazada.vn/shop/ramat-craft
                    </a>
                  </li>
                </ul>
                <strong>2. Đặt sổ cá nhân/ Doanh nghiệp</strong> <br />
                Bạn có thể liên hệ hotline:
                <a href="tel:+84345601839" style={{ marginLeft: "4px" }}>
                  0345601839
                </a>{" "}
                hoặc inbox fanpage để được tư vấn trực tiếp nhé
                <a
                  href="https://www.facebook.com/ramat.notebook"
                  style={{ marginLeft: "4px" }}
                >
                  https://www.facebook.com/ramat.notebook
                </a>
                <br />
                <strong>3. Đặt sổ cho tổ chức, doanh nghiệp Quy trình:</strong>
                <ul>
                  <li>Liên hệ, đặt yêu cầu</li>
                  <li>Tư vấn, báo giá</li>
                  <li>Chốt yêu cầu</li>
                  <li>Báo giá final</li>
                  <li>Chốt đơn hàng, tiến hành lên mẫu, chốt mẫu</li>
                  <li>Đặt cọc, tiến hành sản xuất</li>
                  <li>Giao hàng, thanh toán</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default RefundPolicy;
