import React, { useEffect } from "react";
import BreakCrumb from "../../components/BreakCrumb";
import Container from "../../components/Container";
import Meta from "../../components/Meta";
import "./style.css";
import shipPrice from "../../images/phi-ship.png";
const ShippingPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Meta title="Chính sách vận chuyển" />
      <BreakCrumb title="Chính sách vận chuyển" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
              <h1 className="text-center fw-bold text-uppercase">
                Chính sách vận chuyển
              </h1>
              <p className="fs-5">
                Các chính sách bao gồm cước phí vận chuyển, thời gian và các vấn
                đề khác liên quan đến vận chuyển <br /> CHÍNH SÁCH VẬN CHUYỂN
                <br />
                <strong>1. Cước phí và thời gian vận chuyển</strong>
                <br />- RaMat Notebook hỗ trợ dịch vụ thanh toán online hoặc
                trực tiếp khi nhận hàng trên 63 tỉnh thành.
                <br />- Dịch vụ giao hàng hoạt động từ thứ 2 đến thứ 7 hàng tuần
                ( trừ lễ Tết & CN) RaMat sử dụng dịch vụ của đơn vị Giao hàng
                tiết kiệm và áp dụng biểu phí giao hàng cố định dưới đây. <br />
                <div className="d-flex justify-content-center align-items-center">
                  <img src={shipPrice} alt="" />
                </div>
                <br />
                <strong>2. Thay đổi thông tin giao hàng</strong> <br />
                Các thông tin giao hàng có thể thay đổi bao gồm:
                <ul>
                  <li>Tên người nhận hàng </li>
                  <li>Số điện thoại nhận hàng</li>
                  <li>
                    Địa chỉ nhận hàng (chỉ áp dụng trong trường hợp địa chỉ mới
                    ở cùng một phường với địa chỉ cũ).
                  </li>
                </ul>
                Các bạn muốn thay đổi thông tin giao hàng có thể gọi trực tiếp
                lên Hotline của RamatNoteBook hoặc nhắn tin qua Fanpage Facebook
                RaMat Notebook. <br />
                <strong>3. Kiểm tra tình trạng đơn hàng</strong>
                <br />
                Kiểm tra đơn hàng bằng cách sử dụng SỐ ĐIỆN THOẠI ĐẶT HÀNG hoặc
                MÃ ĐƠN HÀNG để thông báo tới bộ phận Chăm sóc khách hàng qua
                Hotline hoặc Fanpage Facebook.
                <br />
                <strong>
                  4. Khi nhận hàng có được xem sản phẩm trước khi thanh toán
                </strong>
                <br />
                RaMat không hỗ trợ kiểm tra sản phẩm trước khi thanh toán. Nếu
                các bạn gặp vấn đề phát sinh sau khi nhận hàng hoặc trong quá
                trình sử dụng có thể liên hệ ngay với chúng mình để được hỗ trợ
                kịp thời.
                <br /> Để bảo vệ quyền lợi của khách hàng, bạn vui lòng quay
                clip lại quá trình mở hàng để xử lý nếu có trường hợp khiếu nại,
                hàng lỗi. <br />
                <strong color="red">*Lưu ý:</strong> RaMat sử dụng dịch vụ vận
                chuyển của bên thứ 3 do đó nhân viên giao hàng không phải nhân
                viên trực thuộc cửa hàng. Trường hợp có phát sinh các vấn đề
                không hài lòng như thái độ nhân viên giao hàng có thể liên hệ
                với chúng mình, RaMat sẽ làm việc lại với đơn vị vận chuyển để
                các bạn có trải nghiệm mua hàng tốt hơn trong những lần mua hàng
                kế tiếp.
                <br /> Xin cảm ơn!
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ShippingPolicy;
