/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Meta from "../../components/Meta";
import BreakCrumb from "../../components/BreakCrumb";
import { Container } from "react-bootstrap";
const HandmadeGift = () => {
  return (
    <>
      <Meta title="Handmade Gift" />
      <BreakCrumb title="Handmade Gift" />
      <Container>
        <div className="row">
          <div className="col-12">
            <h1
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "100%" }}
            >
              <img
                height="100%"
                width="100%"
                style={{ backgroundPosition: "center", borderRadius: "10px" }}
                src="https://img.freepik.com/premium-vector/grand-opening-soon-sale-banner-design-template-with-3d-editable-text-effect_622741-64.jpg?w=740"
              />
            </h1>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HandmadeGift;
