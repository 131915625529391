import React, { useCallback, useEffect, useState } from "react";
import BreakCrumb from "../../components/BreakCrumb";
import Meta from "../../components/Meta";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineHeart } from "react-icons/ai";
import { FaShareSquare } from "react-icons/fa";
import Container from "../../components/Container";
import { useDispatch, useSelector } from "react-redux";
import {
  addRating,
  addToWishList,
  getAProduct,
  getAllProduct,
} from "../../features/products/productSlice";
import { toast } from "react-toastify";
import { AddToCart, getUserCart } from "../../features/user/userSlice";
import { BsCartPlus } from "react-icons/bs";
import addcart from "../../images/add-cart.svg";
import view from "../../images/view.svg";
import wish from "../../images/wish.svg";
import book02 from "../../images/book-02.png";
const SingleProduct = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getProductSlug = location.pathname.split("/")[2];

  const [quantity, setQuantity] = useState(1);
  const [comment, setComment] = useState(null);
  const [copySuccess, setCopySuccess] = useState("");
  const productsState = useSelector((state) => state?.product?.data);

  const productState = useSelector((state) => state?.product?.singleProduct);
  const [activeImg, setActiveImg] = useState(productState?.images?.[0]?.url);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getData = useCallback(() => {
    dispatch(getAProduct(getProductSlug));
    dispatch(getUserCart());
    dispatch(getAllProduct());
  }, [dispatch, getProductSlug]);

  useEffect(() => {
    getData();
  }, [getData]);

  const uploadCart = () => {
    dispatch(
      AddToCart({
        productId: productState?._id,
        quantity,
        price: productState?.price,
      }),
    );
  };

  const addRatingToProduct = () => {
    if (comment === null) {
      toast.error("Viết đánh giá về sản phẩm!");
      return false;
    } else {
      dispatch(
        addRating({
          // star: star,
          comment: comment,
          prodId: getProductSlug,
        }),
      );
      setTimeout(() => {
        dispatch(getAProduct(getProductSlug));
      }, 200);
    }
    return false;
  };

  const copyToClip = async () => {
    await navigator.clipboard.writeText(window.location.href);
    setCopySuccess("Copied");
    if (copySuccess === "Copied") {
      toast.success("Copy đường dẫn thành công!");
    } else {
      toast.error("Copy đường dẫn bị lỗi!");
    }
  };

  const addToWish = (id) => {
    dispatch(addToWishList(id));
  };

  const handleRedirect = (slug) => {
    window.location.assign(`/product/` + slug);
  };

  return (
    <>
      <Meta
        title={productState?.title ? productState?.title : "Ramat NoteBook"}
      />
      <BreakCrumb
        title={productState?.title ? productState?.title : "Ramat NoteBook"}
      />
      <Container class1="main-product-wrapper home-wrapper-2 pt-2">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="main-product-image">
              <img
                src={activeImg || productState?.images?.[0]?.url}
                alt="Thumbnail Product"
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="d-flex mx-auto bg-white my-2 px-2 rounded-3">
              <div className="row">
                {productState?.images?.map((item, index) => {
                  return (
                    <div
                      className="col-3 col-md-3 p-2"
                      key={index}
                      onClick={() => setActiveImg(item.url)}
                    >
                      <img
                        src={item.url}
                        className="img-fluid"
                        alt="thumnail"
                        style={{ borderRadius: "8px" }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="main-product-details">
              <div className="border-bottom">
                <h3 className="title">
                  {productState?.title || "Tên sản phẩm đang được cập nhật...."}
                </h3>
              </div>
              <div className="border-bottom py-3">
                <p className="price d-flex align-items-center gap-10">
                  <div className="fs-4 text-danger">
                    {productState?.discount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "VND",
                    }) || `Liên hệ Hotline: 0777 077 293`}
                  </div>
                  <del className="fs-6 mb-0d">
                    {productState?.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "VND",
                    }) || `Liên hệ Hotline: 0777 077 293`}
                  </del>
                </p>
                <div className="d-flex align-items-center gap-10">
                  <p className="mb-0 t-review">(2 đánh giá)</p>
                </div>
                <a href="#review" className="review-btn">
                  Viết đánh giá
                </a>
              </div>
              <div className="border-bottom py-3">
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Mã sản phẩm:</h3>
                  <p className="product-data mb-0">
                    {productState?.codeProduct}
                  </p>
                </div>

                <div className="d-flex gap-10 flex-column mt-2 mb-3">
                  <h3 className="product-heading">Khổ giấy:</h3>
                  <div className="d-flex flex-wrap gap-15">
                    <span className="badge border border-1 bg-white text-dark border-secondary">
                      {productState?.paperSize}
                    </span>
                  </div>
                </div>
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Loại Giấy:</h3>
                  <p className="product-data">{productState?.kindOfPaper}</p>
                </div>
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Số trang:</h3>
                  <p className="product-data">{productState?.pageNumber}</p>
                </div>
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Tags:</h3>
                  <p
                    className="product-data"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid",
                      padding: "3px",
                      backgroundColor: "#1da5ff",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    {productState?.tags}
                  </p>
                </div>
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Trạng thái:</h3>
                  <p className="product-data mb-0">
                    {productState?.quantity - productState?.sold > 0 ? (
                      <p className="mb-0" style={{ color: "#30b68a" }}>
                        Còn hàng
                      </p>
                    ) : (
                      <p style={{ color: "red" }}>Hết hàng</p>
                    )}
                  </p>
                </div>
                <div className="d-flex gap-15 flex-row row align-items-center mt-2 mb-3">
                  <div className="d-flex align-items-center gap-2">
                    <h3 className="product-heading">Số lượng:</h3>
                    <div>
                      <input
                        type="number"
                        style={{ width: "50px" }}
                        min={1}
                        max={10}
                        className="form-control"
                        onChange={(e) => setQuantity(e.target.value)}
                        value={quantity}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-15 ms-6">
                    <button
                      onClick={uploadCart}
                      className="button border-0 px-3"
                      type="button"
                    >
                      {localStorage.getItem("customer") !== null ? (
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <BsCartPlus size={25} /> Thêm vào giỏ hàng
                        </div>
                      ) : (
                        <a
                          href="/login"
                          className="text-decoration-none text-white d-flex align-items-center gap-2 btnAddToCart"
                        >
                          <BsCartPlus size={25} />
                          <div className="textAddToCart text-white">
                            Thêm vào giỏ hàng
                          </div>
                        </a>
                      )}
                    </button>
                    <a
                      href="/wishlist"
                      className="wishlist d-flex align-items-center"
                    >
                      <AiOutlineHeart /> Danh sách ước
                    </a>
                  </div>
                </div>
                <div className="d-flex gap-10 flex-column my-3">
                  <h3 className="product-heading">Giao hàng và trả hàng</h3>
                  <p className="product-data">
                    Miễn phí ship và hoàn trả hàng với đơn hàng có giá trị trên
                    500.000 <br />
                    Chúng tôi sẽ giao đơn hàng của bạn <b>từ 5 - 7 ngày</b>
                  </p>
                </div>
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Chia sẻ sản phẩm</h3>
                  <button
                    className="outline-none border-0 px-2 py-1 rounded-3"
                    onClick={copyToClip}
                  >
                    <FaShareSquare />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container class1="description-wrapper pb-3 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h4 className="mt-2">
              <b>Mô tả sản phẩm</b>
            </h4>
            <div className="description-inner-wrapper">
              <p
                dangerouslySetInnerHTML={{ __html: productState?.description }}
              ></p>
            </div>
          </div>
        </div>
      </Container>
      <Container class1="reviews-wrapper home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h4 id="review">
              <b>Đánh giá sản phẩm</b>
            </h4>
            <div className="review-inner-wrapper">
              <div className="review-head d-flex justify-content-between align-items-end">
                <div>
                  <div className="d-flex align-items-center gap-10">
                    <p className="mb-0">2 đánh giá</p>
                  </div>
                </div>
              </div>
              <div className="review-form py-4">
                <h4>Đánh giá của khách hàng</h4>
                <div className="mb-3">
                  <textarea
                    placeholder="Đánh giá"
                    className="w-100 form-control"
                    cols="30"
                    row="4"
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="button border-0"
                    onClick={addRatingToProduct}
                  >
                    Gửi đánh giá
                  </button>
                </div>
              </div>
              <div className="reviews mt-4">
                {productState &&
                  productState?.ratings?.map((i, index) => {
                    return (
                      <div className="review" key={index}>
                        <div className="d-flex gap-10 align-items-center">
                          <h6 className="mb-0">
                            <b>PhatPham</b>
                          </h6>
                        </div>
                        <p className="mt-3">
                          {i?.comment || "Không có đánh giá"}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container class1="popular-wrapper py-5 home-wrapper-2">
        <h3 className="section-heading">Sản phẩm đề xuất</h3>
        <div className="row">
          {productsState &&
            // eslint-disable-next-line array-callback-return
            productsState?.map((item, index) => {
              if (
                item?.status === "true" &&
                item?.category === productState?.category &&
                index < 6
              ) {
                return (
                  <div key={index} className="col-6 col-md-3">
                    <div className="product-card position-relative my-2">
                      <div className="position-absolute tag-percent">
                        Giảm <br />
                        {((item?.discount * 100) / item?.price).toFixed(0)}%
                      </div>
                      <div className="wishlist-icon position-absolute">
                        <button className="border-0 bg-transparent">
                          <img
                            onClick={() => addToWish(item?._id)}
                            src={wish}
                            alt="wish"
                          />
                        </button>
                      </div>
                      <div className="product-image">
                        <img
                          src={item?.images?.[0]?.url}
                          className="img-fluid"
                          alt={`${item?.title}`}
                        />
                        <img
                          src={item?.images?.[1]?.url || book02}
                          className="img-fluid"
                          alt={`${item?.title}`}
                        />
                      </div>
                      <div className="product-details">
                        <h6 className="type">{item?.category}</h6>
                        <h5
                          className="product-title fs-5"
                          style={{ lineHeight: "20px", height: "50px" }}
                          onClick={() => handleRedirect(item?.slug)}
                        >
                          {item?.title}
                        </h5>
                        <p className="price d-flex justify-content-between">
                          <div>
                            <span className="text-danger fw-bold fs-5">
                              {item?.discount?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </span>
                            &nbsp;{" "}
                            <strike>
                              {item?.price?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </strike>
                          </div>
                          <span className="d-flex align-items-center gap-1 sold">
                            <span>Đã bán</span>
                            {item?.sold}
                          </span>
                        </p>
                      </div>
                      <div className="action-bar position-absolute">
                        <div className="d-flex flex-column gap-15">
                          <button className="border-0 bg-transparent">
                            <img
                              onClick={() => navigate(`/product/` + item?.slug)}
                              src={view}
                              alt="view"
                            />
                          </button>
                          <button className="border-0 bg-transparent">
                            <img src={addcart} alt="addcart" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </Container>
    </>
  );
};

export default SingleProduct;
