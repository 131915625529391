/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import newsletter from "../../images/newsletter.png";
import { ImLocation2 } from "react-icons/im";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import ScrollToTop from "react-scroll-to-top";
import logo from "../../images/logo-header.png";
import { object, string } from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createEmailMarketing } from "../../features/email/emailSlice";
import "./Footer.css";
import { useEffect } from "react";
import { getSocial, selectSocial } from "../../features/Social/socialSlice";
import dmca from "../../images/dmca-compliant-white-bg.png";

const emailSchema = object({
  email: string().email("Vui lòng nhập đúng định dạng email"),
});

const Footer = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailSchema,
    onSubmit: async (values) => {
      await dispatch(createEmailMarketing(values));
    },
  });

  useEffect(() => {
    dispatch(getSocial());
  }, [dispatch]);

  const socialState = useSelector(selectSocial);
  const { data } = socialState;

  return (
    <>
      <ScrollToTop smooth />

      {/* Phương thức thanh toán */}
      <footer className="py-2 home-wrapper-2">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="pay-wrapper py-3">
                <a href="#" className="img-fluid d-flex gap-15 mt-0">
                  <img
                    src="https://cdn.divineshop.vn/static/b77a2122717d76696bd2b87d7125fd47.svg"
                    className="img-fluid w-20"
                    alt="Ví MoMo"
                    title="Ví MoMo"
                  />
                  <img
                    src="https://cdn.divineshop.vn/static/72a3a36fc7c66085b3f442940ba45fde.svg"
                    className="img-fluid w-10"
                    alt="Mobile Banking với VnPay"
                    title="Mobile Banking với VnPay"
                  />
                  <img
                    src="https://cdn.divineshop.vn/static/464c7c79044dea88e86adf0e1b9c064c.svg"
                    className="img-fluid w-20"
                    alt="Thẻ VISA"
                    title="Thẻ VISA"
                  />
                  <img
                    src="https://cdn.divineshop.vn/static/ddb866eb1214c914ea62417879046b99.svg"
                    className="img-fluid w-20"
                    alt="Thẻ Mastercard"
                    title="Thẻ Mastercard"
                  />
                  <small className="text-dark mt-0">
                    và nhiều hình thức thanh toán khác
                  </small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Get Email Sale */}
      <footer className="py-2">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-12 col-md-5">
              <div className="footer-top-data d-flex gap-30 align-items-center">
                <img src={newsletter} alt="newsletter" />
                <h2 className="mb-0 text-white">Đăng kí nhận tin khuyến mãi</h2>
              </div>
            </div>
            <div className="col-12 col-md-7">
              <form onSubmit={formik.handleSubmit}>
                <div className="input-group p-2">
                  <input
                    type="email"
                    className="form-control py-1"
                    placeholder="Nhập Email ở đây nè !"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                  />
                  <button
                    className="input-group-text p-2"
                    id="basic-addon2"
                    type="submit"
                  >
                    Đăng kí
                  </button>
                </div>
                <div className="error">
                  {formik.touched.email && formik.errors.email}
                </div>
              </form>
            </div>
          </div>
        </div>
      </footer>

      {/* Contact */}
      <footer className="py-2">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12 col-md-3 pb-2">
              <h4 className="text-white mb-4 d-flex flex-column text-white fw-bold text-uppercase">
                <Link to="/contact" className="text-white">
                  Liên hệ chúng tôi
                </Link>
                <img
                  src={logo}
                  className="w-100 h-50 mt-2"
                  alt="logo-footer"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                />
              </h4>
              <div>
                <address className="text-white fs-7">
                  <ImLocation2 /> Địa chỉ: {data?.address}
                </address>
                <a
                  href={`tel:${data?.phoneNumber}`}
                  className="mt-4 d-block mb-3 text-white"
                >
                  <BsTelephone /> {data?.phoneNumber}
                </a>
                <a
                  href="mailto:cskh@ramatnotebook.com"
                  className="mt-4 d-block mb-3 text-white"
                >
                  <AiOutlineMail /> {data?.email}
                </a>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-30">
                    <a href={data?.facebook} className="text-white">
                      <BsFacebook className="fs-4" />
                    </a>
                    <a href={data?.instagram} className="text-white">
                      <BsInstagram className="fs-4" />
                    </a>
                    <a href={data?.tiktok} className="text-white">
                      <FaTiktok className="fs-4" />
                    </a>
                  </div>
                  <div className="d-flex justify-content-start align-items-start">
                    <a
                      href="https://www.dmca.com/compliance/ramatnotebook.com"
                      title="DMCA Compliance information for ramatnotebook.com"
                      style={{
                        backgroundColor: "#fff",
                        padding: "5px",
                        borderRadius: "10px",
                      }}
                    >
                      <img src={dmca} alt="DMCA compliant image" />
                    </a>
                    {/* <Link to="/">
                      <div
                        href="//www.dmca.com/Protection/Status.aspx?ID=63d4518d-44fa-44d5-8bcc-b7a05e9c2a4d"
                        title="DMCA.com Protection Status"
                        className="dmca-badge"
                      >
                        <img
                          src="https://images.dmca.com/Badges/dmca_protected_8_120.png?ID=63d4518d-44fa-44d5-8bcc-b7a05e9c2a4d"
                          alt="DMCA.com Protection Status"
                        />
                      </div>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-9 row">
              <div className="col-6 col-md-4">
                <h4 className="text-white mb-4 fw-bold text-uppercase">
                  Thông tin
                </h4>
                <div className="footer-links d-flex flex-column">
                  {/* <Link to="/privacy-policy" className="text-white py-2 mb-1">
                    Chính sách bảo mật
                  </Link> */}
                  <Link
                    to="/huong-dan-dat-hang"
                    className="text-white py-2 mb-1"
                  >
                    Hướng dẫn đặt hàng
                  </Link>
                  <Link
                    to="/chinh-sach-thanh-toan"
                    className="text-white py-2 mb-1"
                  >
                    Chính sách thanh toán
                  </Link>
                  <Link
                    to="/chinh-sach-van-chuyen"
                    className="text-white py-2 mb-1"
                  >
                    Chính sách vận chuyển
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <h4 className="text-white mb-4 fw-bold text-uppercase">
                  Về Chúng Tôi
                </h4>
                <div className="footer-account d-flex flex-column">
                  <Link to="/" end className="text-white py-2 mb-1">
                    Về Chúng Tôi
                  </Link>
                  <Link to="/" end className="text-white py-2 mb-1">
                    Hỏi đáp
                  </Link>
                  <Link to="/contact" end className="text-white py-2 mb-1">
                    Liên hệ
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <h4 className="text-white mb-4 fw-bold text-uppercase">
                  Danh mục sản phẩm
                </h4>
                <div className="footer-links d-flex flex-column">
                  <Link to="/" end className="text-white py-2 mb-1">
                    NoteBook
                  </Link>
                  <Link to="/" end className="text-white py-2 mb-1">
                    Pen
                  </Link>
                  <Link to="/" end className="text-white py-2 mb-1">
                    Accessories
                  </Link>
                  <Link to="/" end className="text-white py-2 mb-1">
                    Order
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* CopyRight */}
      <footer className="py-2">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-white">
                &copy; {new Date().getFullYear()} Powered By Ramat
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
