/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { BsBookmarkCheckFill, BsSearch } from "react-icons/bs";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import {
  AiFillGift,
  AiFillHome,
  AiOutlineMenu,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { FaToolbox, FaUserCircle, FaBlogger } from "react-icons/fa";
import logoHeader from "../../images/logo-header.png";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getAProduct } from "../../features/products/productSlice";
import { RxCrossCircled } from "react-icons/rx";
import { MdEvent, MdHistoryEdu, MdPermContactCalendar } from "react-icons/md";
import logoAvatar from "../../images/avatar.png";
import { getUserCart } from "../../features/user/userSlice";
import { configToken } from "../../utils/verifyToken";
import { getSocial, selectSocial } from "../../features/Social/socialSlice";
import Navbar from "./Navbar";

const Header = () => {
  const dispatch = useDispatch();
  const [total, setTotal] = useState(null);
  const cartState = useSelector((state) => state?.auth?.cartProduct?.cart);
  const authState = useSelector((state) => state?.auth);
  const productState = useSelector((state) => state?.product?.data);
  const [productOpt, setProductOpt] = useState(true);
  const [paginate] = useState(true);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserCart(configToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < cartState?.length; index++) {
      sum = sum + Number(cartState?.[index]?.quantity);
      setTotal(sum);
    }
  }, [cartState]);

  useEffect(() => {
    let data = [];
    for (let index = 0; index < productState?.length; index++) {
      const element = productState[index];
      data.push({ id: index, prod: element?.slug, name: element?.title });
    }
    setProductOpt(data);
  }, [productState]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getSocial());
  }, [dispatch]);

  const socialState = useSelector(selectSocial);
  const { data } = socialState;

  return (
    <>
      <header className="header-upper">
        <div className="container-xxl">
          <div className="position-relative d-flex justify-content-between align-content-center">
            {/* Logo header */}
            <div className="my-0">
              <Link to="/" className="logo-header my-1">
                <img
                  src={logoHeader}
                  alt="logo-header"
                  style={{
                    width: "150px",
                    height: "55px",
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            {/* Search product */}
            <div className="py-2 my-auto w-50">
              <div className="input-group">
                <Typeahead
                  id="search"
                  onPaginate={() => console.log("Results paginated")}
                  onChange={(selected) => {
                    navigate(`/product/${selected[0]?.prod}`);
                    dispatch(getAProduct(selected[0]?.prod));
                  }}
                  options={productOpt}
                  paginate={paginate}
                  labelKey={"name"}
                  placeholder="Tìm kiếm sản phẩm ....."
                  minLength={2}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary input-group-text"
                >
                  <BsSearch className="fs-6 fw-bold" />
                </button>
              </div>
            </div>
            {/* Search product */}

            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-15">
                <div>
                  {authState?.user !== null && (
                    <a href="/my-orders" style={{ color: "black" }}>
                      Đơn hàng của tôi
                    </a>
                  )}
                </div>
                <Link
                  to="/cart"
                  className="d-flex align-items-center gap-10 position-relative cart-mobile"
                >
                  <AiOutlineShoppingCart
                    size={30}
                    className="fs-4 color-dark text-decoration-none cart-icon-mobile"
                    style={{ color: "black" }}
                  />
                  <span
                    className="badge fw-bold position-absolute border-circle bg-danger"
                    style={{
                      top: "-10px",
                      right: "-15px",
                      color: "white",
                      border: "2px solid #fff",
                      borderRadius: "50%",
                    }}
                  >
                    {total ? total : 0}
                  </span>
                </Link>
                <div className="d-flex align-items-center gap-10">
                  <a href={data?.instagram} className="text-dark">
                    <BsInstagram className="fs-4" size={30} />
                  </a>
                  <a href={data?.facebook} className="text-dark">
                    <BsFacebook className="fs-4" size={30} />
                  </a>
                </div>
                <div>
                  {authState?.user === null || undefined ? (
                    <Link to="/login">
                      <FaUserCircle className="fs-4 text-dark" size={30} />
                    </Link>
                  ) : (
                    <>
                      <a href="/my-profile">
                        <img
                          src={logoAvatar}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                            borderRadius: "50%",
                            border: "2px solid #0a7cff",
                            padding: "4px",
                          }}
                        />
                      </a>
                      <div className="dropdown">
                        <div
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <h5 className="mb-0">
                            <img
                              src={logoAvatar}
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "contain",
                                borderRadius: "50%",
                                border: "2px solid #0a7cff",
                                padding: "4px",
                              }}
                            />
                          </h5>
                        </div>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                          style={{ borderRadius: "10px", zIndex: "99999" }}
                        >
                          <li
                            className="dropdown-item py-1 mb-1"
                            style={{
                              height: "auto",
                              lineHeight: "20px",
                              cursor: "pointer",
                            }}
                          >
                            <Link className="dropdown-item" to="/my-profile">
                              Thông tin tài khoản
                            </Link>
                          </li>
                          <li
                            className="dropdown-item py-1 mb-1"
                            style={{
                              height: "auto",
                              lineHeight: "20px",
                              cursor: "pointer",
                            }}
                          >
                            <Link
                              className="dropdown-item"
                              to="/change-password"
                            >
                              Thay đổi mật khẩu
                            </Link>
                          </li>
                          <li
                            className="dropdown-item py-1 mb-1"
                            style={{
                              height: "auto",
                              lineHeight: "20px",
                              cursor: "pointer",
                            }}
                          >
                            <Link className="dropdown-item" to="/my-orders">
                              Đơn hàng của tôi
                            </Link>
                          </li>
                          <li
                            className="dropdown-item py-1 mb-1"
                            style={{
                              height: "auto",
                              lineHeight: "20px",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              className="dropdown-item"
                              onClick={handleLogout}
                            >
                              Đăng xuất
                            </div>
                          </li>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="header-bottom">
        <div className="container">
          <div className="menu-links">
            <div className="collapse navbar-collapse col-12 d-flex align-items-center justify-content-center mx-auto gap-15">
              <Navbar />
            </div>
          </div>
        </div>
      </header>

      {/* Header Mobile */}
      <header className="header-mobile">
        <div className="container-xxl d-flex justify-content-between align-items-center position-relative">
          {/* Button Menu Mobile */}
          <button className="btn button-menu outline-none ">
            <AiOutlineMenu
              className="icon-menu p-1"
              size={40}
              onClick={() => setOpen(true)}
            />
          </button>
          <Link to="/" className="logo-header my-2">
            <img
              src={logoHeader}
              alt="logo-header"
              style={{
                width: "100%",
                height: "55px",
                objectFit: "contain",
              }}
            />
          </Link>
          <Link
            to="/cart"
            className="d-flex align-items-center gap-10 position-relative me-2"
          >
            <AiOutlineShoppingCart
              size={30}
              className="fs-4 color-dark text-decoration-none cart-icon-mobile"
              style={{ color: "black" }}
            />
            <span
              className="badge fw-bold position-absolute border-circle bg-danger"
              style={{
                top: "-10px",
                right: "-15px",
                color: "white",
                border: "2px solid #fff",
                borderRadius: "50%",
              }}
            >
              {total ? total : 0}
            </span>
          </Link>
          {/* Menu Mobile */}
          {open && (
            <div
              className="top-0 left-0 position-absolute"
              style={{
                height: "100vh",
                backgroundColor: "#ffff",
                marginLeft: "-15px",
                borderRadius: "0px 10px 10px 10px",
              }}
            >
              <div className="d-flex justify-content-between align-items-center bg-primary">
                <div className="profile d-flex mx-auto">
                  {authState?.user === null ? (
                    <div
                      className="d-flex gap-3 align-items-center py-2 text-white"
                      onClick={() => navigate("/login")}
                    >
                      <FaUserCircle size={40} className="fs-4 text-white" />
                      Đăng nhập
                    </div>
                  ) : (
                    <>
                      <a href="/my-profile">
                        <h5 className="mb-0 fw-bold text-dark">
                          {authState?.user?.firstname +
                            authState?.user?.lastname}
                        </h5>
                      </a>
                      {/* <div className="d-flex gap-3 align-items-center dropdown">
                        <div
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown1"
                          aria-expanded="false"
                        >
                          <h5 className="mb-0 fw-bold text-dark">
                            {authState?.user?.firstname +
                              authState?.user?.lastname}
                          </h5>
                        </div>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                          style={{ borderRadius: "10px" }}
                        >
                          <li
                            className="dropdown-item py-1 mb-1"
                            style={{ height: "auto", lineHeight: "20px" }}
                            onClick={() => setOpen(false)}
                          >
                            <Link className="dropdown-item" to="/my-profile">
                              Thông tin tài khoản
                            </Link>
                          </li>
                          <li
                            className="dropdown-item py-1 mb-1"
                            style={{ height: "auto", lineHeight: "20px" }}
                            onClick={() => setOpen(false)}
                          >
                            <Link className="dropdown-item" to="/my-orders">
                              Đơn hàng của tôi
                            </Link>
                          </li>
                          <li
                            className="dropdown-item py-1 mb-1"
                            style={{ height: "auto", lineHeight: "20px" }}
                            onClick={() => setOpen(false)}
                          >
                            <div
                              className="dropdown-item"
                              onClick={handleLogout}
                            >
                              Đăng xuất
                            </div>
                          </li>
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
                <RxCrossCircled
                  size={30}
                  className="mt-1 icon-cross"
                  onClick={() => setOpen(false)}
                  style={{ cursor: "pointer", color: "#fff" }}
                />
              </div>

              <div
                style={{
                  paddingLeft: "2rem",
                  paddingBottom: "6px",
                  paddingTop: "6px",
                  fontWeight: "600",
                }}
              >
                {authState?.user !== null && (
                  <a href="/my-orders" style={{ color: "black" }}>
                    Đơn hàng của tôi
                  </a>
                )}
              </div>
              <Navbar />

              {/* <ul className="d-flex flex-column menu-header-mobile">
                <li onClick={() => setOpen(false)}>
                  <Link
                    to="/"
                    className="nav-link link-secondary text-dark fw-bold fs-5"
                  >
                    <AiFillHome size={20} /> Trang Chủ
                  </Link>
                </li>

                <li onClick={() => setOpen(false)}>
                  <Link
                    to="/"
                    className="nav-link link-dark text-dark fw-bold fs-5"
                  >
                    <MdHistoryEdu size={20} /> Câu chuyện của Ramat
                  </Link>
                </li>

                <li onClick={() => setOpen(false)}>
                  <Link
                    to="/product"
                    className="nav-link link-dark text-dark fw-bold fs-5"
                  >
                    <BsBookmarkCheckFill size={20} /> Sổ tay
                  </Link>
                </li>

                <li onClick={() => setOpen(false)}>
                  <Link
                    to="/handmade-gift"
                    className="nav-link link-dark text-dark fw-bold fs-5"
                  >
                    <AiFillGift size={20} /> Quà tặng
                  </Link>
                </li>

                <li onClick={() => setOpen(false)}>
                  <Link
                    to="/"
                    className="nav-link link-dark text-dark fw-bold fs-5"
                  >
                    <FaToolbox size={20} /> Phụ kiện
                  </Link>
                </li>

                <li onClick={() => setOpen(false)}>
                  <Link
                    to="/"
                    className="nav-link link-dark text-dark fw-bold fs-5"
                  >
                    <MdEvent size={20} /> Sự kiện
                  </Link>
                </li>

                <li onClick={() => setOpen(false)}>
                  <Link
                    to="/contact"
                    className="nav-link link-dark text-dark fw-bold fs-5"
                  >
                    <MdPermContactCalendar size={20} /> Liên hệ
                  </Link>
                </li>
              </ul> */}
              <div className="d-flex mx-4 my-auto">
                <div className="input-group">
                  <Typeahead
                    id="search"
                    onPaginate={() => console.log("Results paginated")}
                    onChange={(selected) => {
                      navigate(`/product/${selected[0]?.prod}`);
                      dispatch(getAProduct(selected[0]?.prod));
                    }}
                    options={productOpt}
                    paginate={paginate}
                    labelKey={"name"}
                    placeholder="Tìm kiếm sản phẩm ....."
                    minLength={2}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary input-group-text"
                  >
                    <BsSearch className="fs-6 fw-bold" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
