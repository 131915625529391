import React, { useState, useEffect } from "react";

function CountdownTimer({ initialSeconds, isPaused, onClose }) {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let intervalId;

    if (!isPaused) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            clearInterval(intervalId);
            onClose(false);
            return 0;
          } else {
            return prevSeconds - 1;
          }
        });
      }, 1000);
    }

    // Clean up the interval on component unmount or when isPaused changes
    return () => clearInterval(intervalId);
  }, [isPaused, onClose]); // Update the dependency array

  // Calculate the progress percentage
  const progressPercentage =
    ((initialSeconds - seconds) / initialSeconds) * 100;

  // Format the remaining time as MM:SS
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div>
      <p className="text-center d-flex align-items-center justify-content-center">
        Đơn hàng sẽ hết hạn sau: {formatTime(seconds)}
      </p>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressPercentage}%` }}
          aria-valuenow={progressPercentage}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
}

export default CountdownTimer;
