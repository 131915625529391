import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import socialService from "./socialService";

const moduleName = "social";

export const getSocial = createAsyncThunk(
  `${moduleName}/get`,
  async (thunkAPI) => {
    try {
      // console.log(data);
      const response = await socialService.getSocial();
      if (response?.data?.result) {
        const results = {
          data: response?.data?.data,
        };
        return results;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const initialState = {
  social: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  data: [],
};

export const socialSlice = createSlice({
  name: "social",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSocial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSocial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload?.data;
      })
      .addCase(getSocial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });
  },
});

export const selectSocial = (state) => state?.social;

export default socialSlice.reducer;
