export const menuItems = [
  {
    title: "Trang chủ",
    link: "/",
  },
  {
    title: "Câu chuyện của Ramat",
    link: "/",
    submenu: [
      {
        title: "Giới thiệu và hành trình phát triển",
      },
      {
        title: "Workshop",
        submenu: [
          {
            title: "Tự thiết kế/ decor sổ tay",
          },
          {
            title: "May sổ tay truyền thống",
          },
          {
            title: "Khảm đá Mosaic",
          },
          {
            title: "Resin",
          },
        ],
      },
      {
        title: "Thiết kế",
      },
    ],
  },
  {
    title: "Sổ tay",
    link: "/product",
    submenu: [
      {
        title: "Sổ lò xo",
      },
      {
        title: "Sổ gáy ghim",
      },
      {
        title: "Sổ gáy may chỉ",
      },
      {
        title: "Album dán ảnh",
      },
      {
        title: "Các loại sổ đặc biệt",
      },
    ],
  },
  {
    title: "Quà tặng",
    link: "/",
    submenu: [
      {
        title: "Sổ tay bìa hoa khô",
      },
      {
        title: "Vẽ bìa sổ theo yêu cầu",
      },
      {
        title: "In bìa sổ theo yêu cầu",
      },
      {
        title: "In ảnh/ In lịch cá nhân",
      },
    ],
  },
  {
    title: "Phụ kiện",
    link: "/",
    submenu: [
      {
        title: "Buts",
      },
      {
        title: "Bookmarks",
      },
      {
        title: "Văn phòng phẩm",
      },
      {
        title: "Sticker",
      },
      {
        title: "Khác",
      },
    ],
  },
  {
    title: "Sự kiện",
    link: "/contact",
  },
  {
    title: "Liên hệ",
    link: "/contact",
  },
];
