import React, { useState } from "react";
import Meta from "../../components/Meta";
import BreakCrumb from "../../components/BreakCrumb";
import Container from "../../components/Container";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useDispatch } from "react-redux";
import { ChangePass } from "../../features/user/userSlice";
import { FiEdit } from "react-icons/fi";
import { required } from "../../utils/Contants";
import { configToken } from "../../utils/verifyToken";
const profileSchema = object({
  password: string().required(required.passwordOld),
  passwordNew: string().required(required.passwordNew),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  // const userState = useSelector((state) => state?.auth?.user);
  const [edit, setEdit] = useState(true);

  const handleEdit = () => {
    setEdit(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      passwordNew: "",
    },
    validationSchema: profileSchema,
    onSubmit: (values) => {
      //   console.log(values);
      dispatch(ChangePass({ data: values, configUpdateUser: configToken }));
      setEdit(true);
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 300);
      //   toast.success("Cập nhật thành công");
    },
  });
  return (
    <>
      <Meta title="Thông tin của tôi" />
      <BreakCrumb title="Thông tin của tôi" />
      <Container class1="store-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="fw-bold">Cập nhật mật khẩu</h3>
              <div
                className="p-1"
                style={{
                  border: "1px solid",
                  borderRadius: "10px",
                  cursor: "pointer",
                  backgroundColor: "#ffe97a",
                }}
              >
                <FiEdit
                  className="edit-profile"
                  onClick={handleEdit}
                  size={30}
                />
              </div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="w-50 mb-3 col-12">
                <label className="form-label">Mật khẩu cũ</label>
                <input
                  type="text"
                  className="form-control"
                  id="example1"
                  name="password"
                  placeholder="Nhập mật khẩu cũ"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  onBlur={formik.handleBlur("password")}
                  disabled={edit}
                />
                <div className="error">
                  {formik.touched.password && formik.errors.password}
                </div>
              </div>
              <div className="w-50 mb-3 col-12">
                <label className="form-label">Mật khẩu mới</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập mật khẩu mới"
                  name="passwordNew"
                  value={formik.values.passwordNew}
                  onChange={formik.handleChange("passwordNew")}
                  onBlur={formik.handleBlur("passwordNew")}
                  disabled={edit}
                />
                <div className="error">
                  {formik.touched.passwordNew && formik.errors.passwordNew}
                </div>
              </div>
            </div>
            {edit === false && (
              <button type="submit" className="btn btn-primary">
                Cập nhật mật khẩu
              </button>
            )}
          </form>
        </div>
      </Container>
    </>
  );
};

export default ChangePassword;
