import "./style.css";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import Marquee from "react-fast-marquee";
import moment from "moment";
import Meta from "../components/Meta";
import BlogCard from "../components/BlogCard";
import SpecialProduct from "../components/SpecialProduct/SpecialProduct";
import Container from "../components/Container";
import { services } from "../utils/Data";
import Banner from "../images/banner.png";
import Banner2 from "../images/banner-2.png";
import Banner3 from "../images/banner-3.png";
import { getAllBlog } from "../features/blog/blogSlice";
import { getAll } from "../features/products/productSlice";
import wish from "../images/wish.svg";
import book02 from "../images/book-02.png";
import anne from "../images/brand-02.png";
import boxmarket from "../images/brand-99.png";
import mle from "../images/mailamenglish.png";
import thanhhao from "../images/thanh-hao.png";
import vtmusic from "../images/VT-music.png";
import vanlang from "../images/van-lang.png";
import vietaustr from "../images/viet-austr.png";
import bitrimex from "../images/betrimex.png";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
import { addToWishList } from "../features/products/productSlice";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProducts = useCallback(() => {
    dispatch(getAll());
  }, [dispatch]);

  const getBlogs = useCallback(() => {
    dispatch(getAllBlog());
  }, [dispatch]);

  useEffect(() => {
    getBlogs();
    getProducts();
    window.scrollTo(0, 0);
  }, [getBlogs, getProducts]);

  const productState = useSelector((state) => state?.product?.data);
  const blogState = useSelector((state) => state?.blog?.blog);

  const addToWish = (id) => {
    dispatch(addToWishList(id));
  };

  return (
    <>
      <Meta title="Ramat Note Book" />
      {/* Banner Slide */}
      <div className="home-wrapper-1">
        <div className="carousel-wrapper slide-header w-100 h-100">
          <Carousel fade>
            <Carousel.Item>
              <img
                className="d-block w-100 h-100 img-fluid rounded slide-header-image"
                src={Banner}
                alt="Firsr slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 h-100 img-fluid rounded slide-header-image"
                src={Banner2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 h-100 img-fluid rounded slide-header-image"
                src={Banner3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      {/* Danh mục sản phẩm */}
      <Container class1="collection-warpper py-4 home-wrapper-1">
        <div className="col-12 pb-4">
          <h3 className="section-heading text-center title_lines">
            Bộ sưu tập
          </h3>
        </div>
        <div className="row collection-mobile">
          <div className="col-12 col-md-3 col-lg-3 d-flex align-items-center justify-content-center">
            <div className="colection-card text-center">
              <h5 className="text-dark">Sổ tay quà tặng</h5>
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-3 d-flex align-items-center justify-content-center">
            <div className="colection-card text-center">
              <h5 className="text-dark">Sổ tay thiết kế</h5>
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-3 d-flex align-items-center justify-content-center">
            <div className="colection-card text-center">
              <h5 className="text-dark">Handmade</h5>
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-3 d-flex align-items-center justify-content-center">
            <div className="colection-card text-center">
              <h5 className="text-dark">
                Quà tặng <br />
                Doanh nghiệp
              </h5>
            </div>
          </div>
        </div>
      </Container>

      {/* Dịch vụ */}
      <Container class1="home-wrapper-1 pb-4">
        <div className="row">
          <div className="col-12">
            <div className="services d-flex align-items-center justify-content-between collection-mobile-down">
              {services &&
                services?.map((item, index) => {
                  return (
                    <div
                      className="d-flex align-items-center gap-15 collection-mobile-down"
                      key={index}
                    >
                      <img src={item?.image} alt={item?.title} />
                      <div>
                        <h6 className="fw-bold">{item?.title}</h6>
                        <p className="mb-0">{item?.tagline}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Container>

      {/* Product */}
      <Container class1="featured-wrapper py-4 home-wrapper-2">
        <div className="row">
          <div className="col-12 pb-4">
            <h3 className="section-heading text-center py-2 mb-0 title_lines">
              Sản phẩm nổi bật
            </h3>
          </div>
          {productState
            ?.filter(
              (productState) =>
                productState?.status === "true" &&
                productState?.home === "true",
            )
            ?.map((item, index) => (
              <div key={index} className="col-6 col-md-3">
                <div className="product-card position-relative my-2">
                  <div className="position-absolute tag-percent">
                    Giảm <br />
                    {((item?.discount * 100) / item?.price).toFixed(0)}%
                  </div>
                  <div className="wishlist-icon position-absolute">
                    <button className="border-0 bg-transparent">
                      <img
                        onClick={() => addToWish(item?._id)}
                        src={wish}
                        alt="wish"
                      />
                    </button>
                  </div>
                  <div className="product-image">
                    <img
                      src={item?.images?.[0]?.url}
                      className="img-fluid"
                      alt={`${item?.title}`}
                    />
                    <img
                      src={item?.images?.[1]?.url || book02}
                      className="img-fluid"
                      alt={`${item?.title}`}
                    />
                  </div>
                  <div className="product-details">
                    <h6 className="type">{item?.category}</h6>
                    <h5
                      className="product-title fs-5"
                      style={{ lineHeight: "20px", height: "50px" }}
                      onClick={() => navigate(`/product/` + item?.slug)}
                    >
                      {item?.title}
                    </h5>
                    <div className="price d-flex justify-content-between">
                      <div>
                        <span className="text-danger fw-bold fs-5">
                          {item?.discount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </span>
                        &nbsp;
                        <strike>
                          {item?.price?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </strike>
                      </div>
                      <span className="d-flex align-items-center gap-1 sold">
                        <span>Đã bán</span>
                        {item?.sold}
                      </span>
                    </div>
                  </div>
                  <div className="action-bar position-absolute">
                    <div className="d-flex flex-column gap-15">
                      <button className="border-0 bg-transparent">
                        <img
                          onClick={() => navigate(`/product/` + item?.slug)}
                          src={view}
                          alt="view"
                        />
                      </button>
                      <button className="border-0 bg-transparent">
                        <img src={addcart} alt="addcart" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Container>

      {/* Flash sale */}
      {productState
        ?.filter(
          (product) =>
            product?.tags === "Flash Sale" &&
            product?.status === "true" &&
            product?.flashSale === "true",
        )
        ?.map((item, index) => {
          return (
            <Container class1="featured-wrapper py-2 home-wrapper-2">
              <div className="row">
                <div className="col-12 pb-4">
                  <h3 className="section-heading text-center py-2 mb-0 title_lines">
                    Sản phẩm Sale
                  </h3>
                </div>
                <div className="col-12 col-md-6" key={index}>
                  <SpecialProduct
                    title={item?.title}
                    category={item?.category}
                    totalrating={item?.totalrating.toString()}
                    price={item?.price}
                    discount={item?.discount}
                    sale={item?.flashSale}
                    quantity={item?.quantity}
                    sold={item?.sold}
                    dateSale={item?.dateSale}
                    id={item?._id}
                    slug={item?.slug}
                    image={item?.images?.[0]?.url}
                  />
                </div>
              </div>
            </Container>
          );
        })}

      {/* Blog List */}
      <Container class1="blog-wrapper py-4 home-wrapper-2">
        <div className="row">
          <div className="col-12 pb-4">
            <h3 className="section-heading text-center py-2 mb-0 title_lines">
              Bài viết nổi bật
            </h3>
          </div>
          {blogState &&
            blogState?.map((item, index) => {
              if (index < 4) {
                return (
                  <div className="col-6 col-mb-3 col-lg-3">
                    <BlogCard
                      id={item?._id}
                      title={item?.title}
                      description={item?.description}
                      image={item?.images?.[0]?.url}
                      date={moment(item?.createdAt).add(10, "days").calendar()}
                    />
                  </div>
                );
              }
            })}
        </div>
      </Container>

      {/* Khách hàng */}
      <Container class1="marque-wrapper py-4">
        <div className="row">
          <div className="col-12 pb-4">
            <h3 className="section-heading text-center title_lines">
              Khách hàng
            </h3>
          </div>
          <div className="col-12">
            <div className="marquee-inner-wrapper bg-white p-3 card-wrapper">
              <Marquee className="d-flex">
                <div className="mx-4 w-25">
                  <img src={anne} alt="brand" width="150px" height="150px" />
                </div>
                <div className="mx-4 w-25">
                  <img
                    src={boxmarket}
                    width="150px"
                    height="150px"
                    alt="The Box Market"
                  />
                </div>
                <div className="mx-4 w-25">
                  <img
                    src={mle}
                    width="150px"
                    height="150px"
                    alt="Mai Lam English"
                  />
                </div>
                <div className="mx-4 w-25">
                  <img
                    src={thanhhao}
                    width="150px"
                    height="150px"
                    alt="brand"
                  />
                </div>
                <div className="mx-4 w-25">
                  <img
                    src={bitrimex}
                    width="150px"
                    height="150px"
                    alt="brand"
                  />
                </div>
                <div className="mx-4 w-25">
                  <img src={vtmusic} width="150px" height="150px" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src={vanlang} width="150px" height="150px" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img
                    src={vietaustr}
                    width="150px"
                    height="150px"
                    alt="brand"
                  />
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
