import { axiosClient, base_url, config } from "../../utils/axiosConfig";

const getSocial = async () => {
  return await axiosClient.get(`${base_url}social/get`);
};

const socialService = {
  getSocial,
};

export default socialService;
