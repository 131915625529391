import React from "react";
import Meta from "../../components/Meta";
import "./style.css";
import Container from "../../components/Container";
import CustomInput from "../../components/CustomInput";
import { object, string } from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { forgotPasswordToken } from "../../features/user/userSlice";
import { required } from "../../utils/Contants";
import { useNavigate } from "react-router-dom";

const emailSchema = object({
  email: string().email().required(required.email),
});

const Forgotpassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailSchema,
    onSubmit: (values) => {
      dispatch(forgotPasswordToken(values));
    },
  });

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <>
      <Meta title="Lấy lại mật khẩu" />
      <Container class1="login-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12 login-container">
            <div className="auth-card login-body">
              <h2 className="text-center mb-3 fw-bold">Đặt lại mật khẩu</h2>
              <p className="mt-2 mb-3 text-center sub-title">
                Chúng tôi sẽ gửi đến mail của bạn <br /> một mật khẩu mới để
                đăng nhập
              </p>
              <form
                onSubmit={formik.handleSubmit}
                className="d-flex flex-column gap-10"
              >
                <CustomInput
                  type="email"
                  name="email"
                  placeholder="Nhập email ở đây ...."
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur("email")}
                  value={formik.values.email}
                />
                <div className="error">
                  {formik.touched.email && formik.errors.email}
                </div>

                <div className="d-flex justify-content-center gap-15 align-items-center mt-2">
                  <button type="submit" className="button border-0">
                    Gửi email
                  </button>
                  <button
                    className="button-cancel"
                    type="button"
                    onClick={handleCancel}
                  >
                    Hủy
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Forgotpassword;
